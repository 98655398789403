import { OwnUpBox } from '@rategravity/own-up-component-library';
import React from 'react';
import { metadata } from '../../../data/metadata/images';

/**
 * Component that renders a centered SVG on a background
 * that is split horizontally between two colors.
 */
export const HorizontalColorSplitSVG = ({
  topColor,
  bottomColor,
  SVG,
  height,
  imageName
}: {
  topColor: string;
  bottomColor: string;
  SVG: string;
  height: number;
  imageName: string;
}) => (
  <OwnUpBox
    style={{
      background: `linear-gradient(${topColor} 50%, ${bottomColor} 50%)`,
      height
    }}
  >
    <img
      src={SVG}
      alt={metadata[imageName].alt}
      style={{ margin: 'auto', display: 'block', height: '100%' }}
    />
  </OwnUpBox>
);
