import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox
} from '@rategravity/own-up-component-library';
import React from 'react';
import { metadata } from '../../../data/metadata/images';
import RedSquiggleSVG from '../../../images/find-your-best-mortgage/red-squiggle.svg';

const WrapperStyle = createOwnUpStyle({
  display: 'flex',
  justifyContent: 'center',
  margin: '20px 0'
});

const Wrapper = createOwnUpComponent(OwnUpBox, WrapperStyle);

export const RedSquiggleSeparator = () => (
  <Wrapper>
    <img
      src={RedSquiggleSVG}
      alt={metadata['red-squiggle.svg'].alt}
      style={{ margin: 'auto', display: 'block', width: 70 }}
    />
  </Wrapper>
);
