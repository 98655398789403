import { buildMediaQueries } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpText
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { bestMortgageValueProps } from '../../../data/content';
import { metadata } from '../../../data/metadata/images';
import { colors } from '../../../modules/colors';
import { FullWidthField } from '../../full-width-field';
import { RedSquiggleSeparator } from '../red-squiggle-separator';

type BestMortgageValuePropType = typeof bestMortgageValueProps[0];

const ValuePropWrapperStyle = createOwnUpStyle({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  maxWidth: 700,
  padding: 50,
  variants: {
    smallAndDown: {
      padding: '30px 0'
    }
  }
});

const ValuePropWrapper = createOwnUpComponent('div', ValuePropWrapperStyle);

const HeadlineText = styled(OwnUpText)`
  font-family: 'TiemposHeadline-Medium', 'Palatino', 'Times New Roman', 'serif';
  font-size: 30px;
  font-weight: 'bold';
  letter-spacing: 0.5px;
  line-height: 130%;
  text-align: 'center';
  padding-top: 20px;
  ${buildMediaQueries('smallAndDown', {
    fontSize: '24px'
  })}
`;

const BodyText = styled(OwnUpText)`
  color: ${colors.NIMBUS};
  font-size: 17px;
  padding: 0 20px;
  text-align: 'center';
  line-height: 160%;
  ${buildMediaQueries('smallAndDown', {
    padding: 0
  })}
`;

export const ValueProp = ({
  data: { header, text, Image }
}: {
  data: BestMortgageValuePropType;
}) => (
  <ValuePropWrapper>
    <img alt={metadata[Image.imageName].alt} src={Image.svg} height={Image.height} />
    <HeadlineText>{header}</HeadlineText>
    <RedSquiggleSeparator />
    <BodyText>{text}</BodyText>
  </ValuePropWrapper>
);

const ValuePropsWrapperStyle = createOwnUpStyle({
  alignItems: 'center',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  padding: '50px 30px',
  width: '100%',
  variants: {
    smallAndDown: {
      padding: 30
    }
  }
});

const ValuePropsWrapper = createOwnUpComponent('section', ValuePropsWrapperStyle);

export const ValueProps = ({ data }: { data: BestMortgageValuePropType[] }) => (
  <FullWidthField backgroundColor={colors.WHITE}>
    <ValuePropsWrapper aria-label="value props">
      {data.map((d, i) => (
        <ValueProp data={d} key={i} />
      ))}
    </ValuePropsWrapper>
  </FullWidthField>
);
