import loadable from '@loadable/component';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Hero } from '../components/find-your-best-mortgage/hero';
import { ValueProps } from '../components/find-your-best-mortgage/value-prop';
import { Layout } from '../components/layout';
import { bestMortgageValueProps } from '../data/content';
import { colors } from '../modules/colors';

const CTA = loadable(() => import('../components/find-your-best-mortgage/cta'), {
  resolveComponent: (components) => components.CTA
});

const FindYourBestMortgage = () => (
  <Layout>
    <Helmet>
      <style>{`
        body {
          background-color: ${colors.AQUA}
        }
        nav {
          background-color: ${colors.AQUA}
        }`}</style>
    </Helmet>
    <Hero />
    <ValueProps data={bestMortgageValueProps} />
    <CTA />
  </Layout>
);

export default FindYourBestMortgage;
