import { GetStartedLink } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox
} from '@rategravity/own-up-component-library';
import React from 'react';
import { useMixpanel } from '../../../hooks/use-mixpanel';
import { HeroicButton } from '../../button';

const ButtonWrapperStyle = createOwnUpStyle({
  margin: '40px auto',
  width: 325,
  variants: {
    smallAndDown: {
      margin: '20px auto'
    },
    xsmall: {
      width: '100%'
    }
  }
});

const ButtonWrapper = createOwnUpComponent(OwnUpBox, ButtonWrapperStyle);

export const CTAButton = ({
  text,
  mixpanelLocation
}: {
  text: string;
  mixpanelLocation?: string;
}) => {
  const mixpanel = useMixpanel();

  return (
    <ButtonWrapper>
      <GetStartedLink
        discover={`${process.env.GATSBY_DISCOVER}/get-started`}
        label={text}
        mixpanelEvent={
          mixpanelLocation
            ? {
                hook: mixpanel,
                name: 'Click discover CTA',
                properties: { 'page-location': `find-your-best-mortgage-${mixpanelLocation}` }
              }
            : undefined
        }
      >
        <HeroicButton variant="bestMortgageBanner">{text}</HeroicButton>
      </GetStartedLink>
    </ButtonWrapper>
  );
};
